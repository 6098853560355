import React, { useCallback, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { Button } from "../../components/Button";
import { Navigation } from "../../components/Navigation";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { Helmet } from 'react-helmet';
import { Loading } from '../Loading/Loading';
import { Loading_mainpage } from '../../screens/Loading';
import ReviewsList from "../../components/reviews/ReviewsList";
import Features from "../../components/features/Features";
import Stepper from "../../components/Stepper/Stepper";
import Footer from "../../components/footer/footer";

//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";  // <-- Import useAuth0 hook
//---------------------------------auhentication code----------------------------------------
import "./style.css";

export const HomeNew = () => {
  //---------------------------------auhentication code----------------------------------------
  const { isAuthenticated, loginWithRedirect, logout, user, isLoading, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // state for loading
  const [check_register, setcheck_register] = useState(false); // state for loading
  
  if (isLoading) {
    return <div>Loading ...</div>;
  }
  // import the synchronous data from Database
  const reviews = [
    { stars: '✨✨✨✨✨', comment: 'Resumaster provided personalized feedback and helped me landed my dream job!'},
    { stars: '✨✨✨✨✨', comment: 'Resumaster has been the best resume and cover letter writer I have ever used.' },
    { stars: '✨✨✨✨✨', comment: 'Resumaster provided not just a revamp but valuable insights for tailoring my resume to different job applications. Highly recommended!' },
    { stars: '✨✨✨✨✨', comment: 'Their personalized feedback and suggestions significantly improved my resume and saved me a lot of time.' },
    { stars: '✨✨✨✨✨', comment: 'Great platform!' },
    
  ];
  
  
  if (isAuthenticated && !user.email_verified) {
    navigate('/unverified');
    return <div>Redirecting for email verification...</div>;  // Display a message or loading animation here
  }

  if (isAuthenticated && user.email_verified) {
    navigate('/Dashboard');
  }

  const sendToServer = async () => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return
      }
    //---------------------------------auhentication code----------------------------------------
    //start loading
    setLoading(true);
    // send a POST request
    console.log('Send POST request to API')
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/register_user', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      }
      //---------------------------------auhentication code----------------------------------------
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      //---------------------------------auhentication code----------------------------------------
      if (response.status == 401) {
        throw new Error('Sorry, you must login first')
      }
      //---------------------------------auhentication code----------------------------------------
      setLoading(false);
    }).catch(error => {
      // handle the error
      console.error(error);
      // If there's an error, ensure loading is turned off
      setLoading(false);

      alert(error.message);  // Displaying the error message to the user
    });
  }

  if (loading) {
    // return (
    //     <div className="loading-screen">
    //         <BeatLoader color={"#123abc"} loading={loading} size={40} />
    //         <p>Please wait approximately 90 seconds for the suggestions</p>
    //     </div>
    // );

    return <Loading_mainpage />;
  }

  if (isAuthenticated && !check_register) {
    sendToServer();
    setcheck_register(true);
  }

  //---------------------------------auhentication code----------------------------------------
  return (

    <div className="home-new">
      <Helmet>
      {/* <!-- Google tag (gtag.js) --> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-F812QPTDD9');
          `}
        </script>
      <title>ResuMaster - The best AI resume tool to land your dream job</title>
      <meta property="og:title" content="ResuMaster - The best AI resume tool to boost your interview chances!" />
      <meta property="og:description" content="Customize your resume to match job description, boost your chances of landing interviews" />
      </Helmet>
      <Navigation className="navigation-2202" />
      
      <div className="announcement-banner">
        <div className="banner-text">
        Black Friday is Here! Save 40% - Sign Up Now to Unlock Exclusive Deals!
        </div>
      </div>

      {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <div className="section-1">
        <div className="title-part">
          <div className="frame-2">
            <h1>
              The Ultimate Solution for Your Job Search
            </h1>
            {/* <h1>
              Pave the Way to Your Dream Job
               <br/>with <span className="ribbon">AI Resume Optimizer</span>
            </h1> */}
            <p>
                Optimize Your Resume for Every Application:
                Save Time and Boost Interview Rates with Our Tailored Solutions!
            </p>
          </div>

          <div className="frame-3">
          {
          isAuthenticated ? (
                <Link to="/Dashboard">
                  <Button className="button-instance" text="Get Started"/>
              </Link>
            ) : (
                  <Button className="button-instance" onClick={loginWithRedirect} text="Try it for Free" showArrow />
            )
          }
          </div>
          <hr className="home-header-line"></hr>
          <div className="frame-5">
            <div className="home-metrics-1">
              <h4 className="home-metric-number">50,000+</h4>
              <p className="home-metric-comment">Resume Revisions</p>
            </div>
            <div className="home-metrics-2">
              <h4 className="home-metric-number">98.3%</h4>
              <p className="home-metric-comment">Customer Rating</p>
            </div>
          </div>

        </div>
        <div className="gif-container-2">
          <img src='/img/Home-hero-light.gif' alt="denamic operations" />
        </div>
      </div>

      <div className="section-2">
        <div className="testimonials">
          <div className="testimonial-1">
            <div className="testimonial-user-1">
              <img src="/img/testimonial-user1.png" alt="user-1-img" className="testmonial-user-img"/>
              <h3 className="user-1-name">Sarah T.</h3>
            </div>
            <p className="testimonial-content-1">“ Resumaster helped me land 4 interviews in just two weeks! 
              The personalized resume tips made all the difference."</p>
          </div>

          <div className="testimonial-2">
            <div className="testimonial-user-2">
              <img src="/img/testimonial-user2.png" alt="user-2-img" className="testmonial-user-img"/>
              <h3 className="user-2-name">James H.</h3>
            </div>
            <p className="testimonial-content-1">“ With Resumaster, I can quickly adapt my resume for different roles. 
              This has allowed me to apply for 10+ jobs in just an hour! “</p>
          </div>
        </div>
        {/* <ReviewsList reviews={reviews} /> */}
      </div>

      <div className="section-stepper">
        <button className="features-button">How It Works</button>
        <Stepper />
      </div>

      <div className="section-3">
        <div className="features-title">
          <button className="features-button">FEATURES</button>
          <h2>Crafting the Perfect Documents for Your Dream Job</h2>
          <p>We analyze six key aspects for career suceess and offer tailored 
            suggestions, ensuring your resume is a perfect match for the position
          </p>
        </div>
        <Features />
      </div>

      <div className="section-34">
        <div className="ai-chatbot-intro">
          <h2>Elevate Your Resume with ResuBot: Your AI-Powered Assistant</h2>
          <p>ResuBot is here to revolutionize the way you create and optimize your resume, 
            with personalized suggestions and edits to ensure your resume stands out</p>
        </div>
        <div className="ai-chatbot-img">
            <img src='/img/Home-ai-chatbot.png' alt="chatbot" />
        </div>
      </div>


      <div className="section-4">

        <div className="keys-title">
          <h2>Transforming the way you navigate your job search journey</h2>
          <p>We streamline your job application, reducing time spent and maximizing efficiency in getting interviews</p>
        </div>
        <div className="keys-img">
            <img src='/img/Home-img-section4.png' alt="home keys" />
        </div>
        <div className="keys">
          <div className="keys-1">
            <img src="/img/Home_icon_7.png" alt="icon_7" />
            <h3>Time-Saving</h3>
            <p>Save valuable time with our efficient streamlined resume revision process</p>

          </div>
          <div className="keys-2">
            <img src="/img/Home_icon_8.png" alt="icon_8" />
            <h3>Interview-Boosting</h3>
            <p>Increase your chances of landing interviews with our tailored suggestions</p>
          </div>
        </div>
      </div>

      <div className="section-5">
        <div className="cover-letter">

          <div className="cover-text">
            <h2>Cover Letter Writer</h2>
            <p>We tailor your cover letter to match the position,
              highlighting your skills and experiences in line with the company's values
            </p>
            {
              isAuthenticated ? (
                <Link to="/">
                  <button className="arrow" >Get started</button>
                </Link>
              ) : (
                  <button className="arrow" onClick={loginWithRedirect} >Get started for free</button>
              )
            }
          
          </div>
          
          <div className="cover-gif">
            <img src='/img/Home_gif2.gif' alt="denamic operations for cover letter" />
          </div>

        </div>
        <div className="resume-contain">
          <div className="resume-left resume-gif">
            <img src='/img/Home_gif3.gif' alt="denamic operations for cover letter" />
          </div>
          <div className="resume-opti">
            <h2>Resume Optimization</h2>
            <p>We align your resume with targeted job listings,
              ensuring your resume highlights the most pertiment skills
              and experiences for each job application you submit
            </p>
            {
              isAuthenticated ? (
                <Link to="/">
                  <button className="arrow" >Get started</button>
                </Link>
              ) : (
                <button className="arrow" onClick={loginWithRedirect} >Get started for free</button>
              )
            }
          </div>
        </div> 
      </div>

      <div className="section-6">
        <div className="section-6-text">
            <h2>Elevate your job search now with </h2>
            <h2>RESUMASTER.AI</h2>
        </div>
        <div className="frame-3">
          {
            isAuthenticated ? (
              <Link to="/">
                <Button className="button-instance" text="Get Started" />
              </Link>
            ) : (
              <Button className="button-instance" onClick={loginWithRedirect} text="Try it for Free" showArrow />
            )
          }
        </div>
      </div>
      <div className="foot-box">
        <Footer />
      </div>


      
      {/* //---------------------------------auhentication code---------------------------------------- */}
      {/* {
        isAuthenticated && (
            <div>
              <img src={user.picture} alt={user.name} />
              <h2>{user.name}</h2>
              <p>{user.email}</p>
            </div>
          )
      } */}
      {/* //---------------------------------auhentication code---------------------------------------- */}
    </div>
  );
};
