import React, { useCallback, useState } from 'react'
import { Navigation } from "../../components/Navigation";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";  // <-- Import useAuth0 hook
import { useNavigate } from 'react-router-dom';
import { Loading_mainpage } from '../../screens/Loading';
import CurrentResume from '../CurrentResume/CurrentResume'
import ApplicationHistory from '../ApplicationHistory/ApplicationHistory'
import AddApplication from '../ApplicationHistory/AddApplication';
import CustomSort from '../ApplicationHistory/CustomSort';
import "./style_board.css";
import CustomFilter from '../ApplicationHistory/CustomFilter';

const Dashboard = () => {

    //---------------------------------auhentication code----------------------------------------
    const { getAccessTokenSilently, isAuthenticated, loginWithRedirect, logout, user, isLoading } = useAuth0();
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        return <div>Please log in first to start revise your resume.</div>;
    }
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('Revision History');
    const [revisionHistory, setRevisionHistory] = useState(null);
    const [historyNameInfo, setHistoryNameInfo] = useState(null);
    const [coverletterHistoryNameInfo, setCoverletterHistoryNameInfo] = useState(null);
    // console.log(revisionHistory);
    // console.log(historyNameInfo);
    // console.log(coverletterHistoryNameInfo);
    const [letterHistory, setLetterHistory] = useState(null);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [credits, setCredits] = useState(" "); // Initialize credits state
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isModal1Open, setIsModal1Open] = useState(false);
    const [isModal2Open, setIsModal2Open] = useState(false);
    const [subscriptionEnd, setSubscriptionEnd] = useState(" ");
    const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentResume, setCurrentResume] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const displayedItems = showAll ? paymentHistory : paymentHistory.slice(0, 2);
    // console.log(testingData.Record_content)
    // console.log(letterHistory)
    const [resumeRemoved, setResumeRemoved] = useState(false);
    // console.log(testingData.Record_content)

    // -------------------------Application History Variables-------------------------------
    const [isAddApplicationModalOpen, setIsAddApplicationModalOpen] = useState(false);

    const openAddApplicationModal = () => setIsAddApplicationModalOpen(true);
    const closeAddApplicationModal = () => setIsAddApplicationModalOpen(false);

    const [userApplicationsInfos, setUserApplicationsInfos] = useState([]);

    const addNewApplications = (newAppInfoArray) => {
        const newApplications = newAppInfoArray.map(newAppInfo => ({
            id: newAppInfo.id || null,
            user_id: newAppInfo.user_id || '',
            company: newAppInfo.company || '',
            domain: newAppInfo.domain || null,
            job_position: newAppInfo.job_position || '',
            coverletter_json: newAppInfo.coverletter_json || false,
            job_description: newAppInfo.job_description || false,
            resume_json: newAppInfo.resume_json || false,
            application_status: newAppInfo.application_status || '',
            applied_date: newAppInfo.applied_date || null,
            screen_date: newAppInfo.screen_date || null,
            interview_date: newAppInfo.interview_date || null,
            offer_date: newAppInfo.offer_date || null,
            rejected_date: newAppInfo.rejected_date || null,
        }));
      
        setUserApplicationsInfos(newApplications);
    };

    const fetch_sorted_filtered_queried_applications = async () => {
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return;
        }
        const formData = createFormDataWithCategories();
        const token = await getAccessTokenSilently();
      
        try {
            const response = await fetch('/resumasterapi/fetch_applications', {
                method: 'POST',
                headers: {
                Authorization: `Bearer ${token}`
                },
                body: formData
            });
        
            if (response.status !== 200) {
                throw new Error('Error fetching applications.');
            }
        
            const data = await response.json();
            console.log(data);
            addNewApplications(data);
            console.log('Updated userApplicationsInfos:', data);
        } catch (error) {
            console.error(error);
            setLoading(false);
            alert(error.message);
        }
    };
      

    const handleAddApplicationSubmit = useCallback(async (newApplication) => {
        console.log('New application submitted:', newApplication);
        try {
            await SaveApplicationToDB(newApplication);
            closeAddApplicationModal();
            await fetch_sorted_filtered_queried_applications();
        } catch (error) {
            console.error('Error in handleAddApplicationSubmit:', error);
        }
    }, [closeAddApplicationModal]);
      
    const handleApplicationUpdate = async (newApplication) => {
        console.log('Application updated:', newApplication);
        try {
            await updateApplicationToDB(newApplication);
            await fetch_sorted_filtered_queried_applications();
        } catch (error) {
            console.error('Error in handleApplicationUpdate:', error);
        }
    };

    const handleApplicationDelete = async (newApplication) => {
        console.log('Application deleted:', newApplication);
        try {
            await deleteApplicationToDB(newApplication);
            await fetch_sorted_filtered_queried_applications();
        } catch (error) {
            console.error('Error in handleApplicationDelete:', error);
        }
    };
      

    const formatDateTimeToUTC = (date) => {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const createEndOfDay = (date) => {
        const endOfDay = new Date(date);
        endOfDay.setHours(23, 59, 59, 999);  // 设置为23:59:59.999
        return endOfDay;
    };

    const [filterInfo, setFilterInfo] = useState({
        start_date: '',
        end_date: formatDateTimeToUTC(createEndOfDay(new Date())),
        applied: true,
        screen: true,
        interview: true,
        offer: true,
        rejected: true
    });

    const handleFilterInfo = (field, value) => {
        setFilterInfo(prev => ({ ...prev, [field]: value }));
    };

    const fetch_earliest_application_date = async () => {
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return
          }
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/fetch_earliest_application_date', { //api endpoint
            method: 'GET',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then(response => {
            if (response.status !== 200) {
                throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            return response.json();
        }).then(data => {
            const utcDate = convertToUTCDate(data.date);
            handleFilterInfo('start_date', formatDateTimeToUTC(utcDate));
        }).catch(error => {
            console.error(error);
            alert(error.message);  // Displaying the error message to the user
        });
    }
    

    const convertToUTCDate = (utcString) => {
        const date = new Date(utcString);
        return new Date(Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        ));
    };

    const [sortInfo, setSortInfo] = useState('last_updated')

    const handleSortInfo = (value) => {
        setSortInfo(value);
    };

    const [searchInput, setSearchInput] = useState('');
    const [submittedSearch, setSubmittedSearch] = useState('');

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        console.log('Search input:', searchInput);
        setSubmittedSearch(searchInput.trim());
        // setSearchInput('');
    };

    const handleSearchIconClick = () => {
        console.log('Search input:', searchInput);
        setSubmittedSearch(searchInput.trim());
        // setSearchInput('');
    };

    const createFormDataWithCategories = () => {
        const formData = new FormData();
        const categoryMap = {
          applied: 'Applied',
          screen: 'Screen',
          interview: 'Interview',
          offer: 'Offer',
          rejected: 'Rejected'
        };
        const categories = Object.entries(filterInfo)
          .filter(([key, value]) => categoryMap[key] && value === true)
          .map(([key]) => categoryMap[key]);
        formData.append('sort_requirement', sortInfo);
        formData.append('start_date', filterInfo.start_date);
        formData.append('end_date', filterInfo.end_date);
        formData.append('query', submittedSearch);
        formData.append('categories', JSON.stringify(categories));
        // console.log(categories)
        return formData;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const msg = `start date: ${filterInfo.start_date}, end date: ${filterInfo.end_date}, applied: ${filterInfo.applied}, screen: ${filterInfo.screen}, interview: ${filterInfo.interview}, offer: ${filterInfo.offer}, rejected: ${filterInfo.rejected}, sorted by: ${sortInfo}, query is: ${submittedSearch}`;
                console.log(msg);
                
                // 可以在这里设置loading状态
                // setLoading(true);
                
                await fetch_sorted_filtered_queried_applications();
                
                // 数据获取完成后关闭loading
                // setLoading(false);
            } catch (error) {
                console.error("Error fetching applications:", error);
                // setLoading(false);
            }
        };
    
        fetchData();
    }, [sortInfo, filterInfo, submittedSearch]);
    

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);  // Start loading
            try {
                if (isAuthenticated) {
                    await fetchPaymentHistory();
                    await fetchUserCredits();
                    await fetchCurrentResume();
                    await fetch_earliest_application_date();
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);  // Stop loading after data is fetched or an error occurs
            }
        };
    
        fetchData();
    }, [isAuthenticated, resumeRemoved]);

    const SaveApplicationToDB = async (ApplicationData) => {
        if (!isAuthenticated) {
          alert("Login required: Please login first");
          return;
        }
        const token = await getAccessTokenSilently();
        const formData = new FormData();
        formData.append('company', ApplicationData.company_name);
        if (ApplicationData.company_domain) {
            formData.append('domain', ApplicationData.company_domain);
        }
        formData.append('job_position', ApplicationData.position);
        formData.append('job_description',ApplicationData.job_description);
        formData.append('application_status', ApplicationData.status);
        if (ApplicationData.applied_date) {
            formData.append('applied_date', ApplicationData.applied_date);
        }
        if (ApplicationData.screen_date) {
            formData.append('screen_date', ApplicationData.screen_date);
        }
        if (ApplicationData.interview_date) {
            formData.append('interview_date', ApplicationData.interview_date);
        }
        if (ApplicationData.offer_date) {
            formData.append('offer_date', ApplicationData.offer_date);
        }
        if (ApplicationData.rejected_date) {
            formData.append('rejected_date', ApplicationData.rejected_date);
        }
      
        try {
            const response = await fetch('/resumasterapi/add_applications', {
                method: 'POST',
                headers: {
                Authorization: `Bearer ${token}`
                },
                body: formData
            });
        
            if (response.status !== 201) {
                throw new Error('Sorry, there is an error when saving your application.');
            }
            return; // Indicate success
        } catch (error) {
            console.error(error);
            alert(error.message);
            throw error; // Re-throw to handle it in the calling function
        }
    };

    const updateApplicationToDB = async (ApplicationData) => {
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return
        }
        const token = await getAccessTokenSilently();
        const formData = new FormData();
        formData.append('application_id', ApplicationData.id);
        formData.append('company', ApplicationData.company_name);
        if (ApplicationData.domain) {
            formData.append('domain', ApplicationData.domain);
        }
        formData.append('job_position', ApplicationData.position);
        formData.append('application_status', ApplicationData.status);
        if (ApplicationData.applied_date) {
            formData.append('applied_date', ApplicationData.applied_date);
        }
        if (ApplicationData.screen_date) {
            formData.append('screen_date', ApplicationData.screen_date);
        }
        if (ApplicationData.interview_date) {
            formData.append('interview_date', ApplicationData.interview_date);
        }
        if (ApplicationData.offer_date) {
            formData.append('offer_date', ApplicationData.offer_date);
        }
        if (ApplicationData.rejected_date) {
            formData.append('rejected_date', ApplicationData.rejected_date);
        }

        try {
            const response = await fetch('/resumasterapi/update_applications', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: formData
            });
        
            if (response.status !== 201) {
                throw new Error('Sorry, there is an error when saving your application.');
            }
            return; // Indicate success
        } catch (error) {
            console.error(error);
            alert(error.message);
            throw error; // Re-throw to handle it in the calling function
        }
    };

    const deleteApplicationToDB = async (ApplicationData) => {
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return
        }
        const token = await getAccessTokenSilently();
        const formData = new FormData();
        formData.append('application_id', ApplicationData);

        try {
            const response = await fetch('/resumasterapi/delete_applications', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: formData
            });
        
            if (response.status !== 201) {
                throw new Error('Sorry, there is an error when saving your application.');
            }
            return; // Indicate success
        } catch (error) {
            console.error(error);
            alert(error.message);
            throw error; // Re-throw to handle it in the calling function
        }
    }

    const getHistoryName = (historyNameArray) => {
        let historyNameInfoArrary = [];
    
        historyNameArray.forEach(element => {
            // Parse each element
            let parsedElement = JSON.parse(element);
            
            // Check if namingInfo exists and parse it, otherwise push the default object, this require the user fill in the company name & job position title
            if (parsedElement.namingInfo) {
                let parsedNamingInfo = JSON.parse(JSON.stringify(parsedElement.namingInfo));
                historyNameInfoArrary.push(parsedNamingInfo);
            } else {
                historyNameInfoArrary.push(null);
            }
        });
    
        return historyNameInfoArrary;
    };

    const getCoverletterHistoryName = (coverletterHistoryArray) => {
        let historyNameInfoArrary = [];
    
        coverletterHistoryArray.forEach(element => {
            // Parse each element
            let parsedElement = JSON.parse(element);
            
            // Check if namingInfo exists and parse it, otherwise push the default object
            if (parsedElement.jobPosition) {
                let clJobPosition = parsedElement.jobPosition;
                let clCompany = parsedElement.company;
                let nameRecord = {
                    "jobPosition": clJobPosition,
                    "companyName": clCompany
                };
                historyNameInfoArrary.push(nameRecord);
            } else {
                historyNameInfoArrary.push(null);
            }
        });
    
        return historyNameInfoArrary;
    };

    useEffect(() => {
        if (isSubscribed === true) {
            fetchUserSubscription();
        }
    }, [isSubscribed]);


    const fetchUserCredits = async () => {
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/check_user_status', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            if (data.in_subscription === true) {
                setCredits("Monthly Subscription");
                setIsSubscribed(true);
                //console.log('credits:Monthly Subscription ')
            }
            else {
                setCredits(data.current_credits);
                //console.log(data.current_credits);
                //console.log('credits:',credits)
            }
            //setLoading(false);
        }).catch(error => {
            // handle the error
            console.error("fetch user credits failed:", error);
            // If there's an error, ensure loading is turned off
            // Check if the error is due to a JSON parsing issue
            if (error.message.includes('Unexpected token')) {
                console.error('Received non-JSON response:', error);
                // You might want to set a different state here to handle this specific error
            }
            //setLoading(false);
            alert(error.message);  // Displaying the error message to the user
        });
    }

    const fetchCustomerPortalLink = async () => {
        try {
          // Send a GET request
          console.log('Fetching customer portal link from API');
    
          // Get the user's token for authentication
          const token = await getAccessTokenSilently();
    
          // Call the backend API to get the customer portal link
          const response = await fetch('/resumasterapi/get-customer-portal', {
            method: 'GET',  // Adjust method based on your backend route
            headers: {
              Authorization: `Bearer ${token}`,  // Pass the JWT token for authentication
              'Content-Type': 'application/json'
            }
          });
    
          // Parse the response JSON
          const data = await response.json();
    
          // Check if the response contains a valid URL
          if (response.ok && data.url) {
            console.log('Redirecting to:', data.url);
            // Redirect the user to the customer portal link
            window.location.href = data.url;
          } else {
            console.error('Failed to fetch the customer portal link:', data.error);
            // Handle error case (e.g., show an error message)
            alert('Your customer portal is not available now');
          }
        } catch (error) {
          console.error('Error fetching the customer portal link:', error);
          // Handle any unexpected errors
          alert('An error occurred while fetching the portal link.');
        }
    };

    const fetchUserSubscription = async () => {
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/get_subscription_info', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            setSubscriptionEnd(data.current_period_end);
            if (data.cancel_at_period_end === true) {
                setSubscriptionCancelled(true);
                setIsSubscribed(false);
            }
            //setLoading(false);
        }).catch(error => {
            // handle the error
            console.error("fetch user credits failed:", error);
            // If there's an error, ensure loading is turned off
            // Check if the error is due to a JSON parsing issue
            if (error.message.includes('Unexpected token')) {
                console.error('Received non-JSON response:', error);
                // You might want to set a different state here to handle this specific error
            }
            //setLoading(false);
            alert(error.message);  // Displaying the error message to the user
        });
    }



    const fetchPaymentHistory = async () => {
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/check_payment_history', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            if (data && data.payment_info) {
                setPaymentHistory(data.payment_info);
                
            }
            //update setapiresponse that is used in result.js
            //setApiResponse(data);
            //stop loading and redirect to /result
            //setLoading(false);
            //history.push("/result");
        }).catch(error => {
            // handle the error
            console.error("fetch payment history failed:", error);
            // If there's an error, ensure loading is turned off
            // Check if the error is due to a JSON parsing issue
            if (error.message.includes('Unexpected token')) {
                console.error('Received non-JSON response:', error);
                // You might want to set a different state here to handle this specific error
            }
            //setLoading(false);
            alert(error.message);  // Displaying the error message to the user
        });
    };

    const fetchCurrentResume = async() => {
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/get_dashboard_resume', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            if (data) {
                setCurrentResume(data);
            }
        }).catch(error => {
            // handle the error
            console.error("fetch payment history failed:", error);
            // If there's an error, ensure loading is turned off
            // Check if the error is due to a JSON parsing issue
            if (error.message.includes('Unexpected token')) {
                console.error('Received non-JSON response:', error);
                // You might want to set a different state here to handle this specific error
            }
            alert(error.message);
        });
    };

    const handleRemoveCurrentResume = async() => {

        console.log('Delete Resume');
        closeModal2();
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/remove_dashboard_resume', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
        }).then(response => {
            setResumeRemoved(!resumeRemoved);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
        }).catch(error => {
            // handle the error
            console.error("remove current resume failed:", error);
            if (error.message.includes('Unexpected token')) {
                console.error('Received non-JSON response:', error);
            }
            alert(error.message);
        });
    }

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        window.scrollTo(0, 0);
    };
    const handleNewRevision = () => {
        if (currentResume && currentResume.Record_exist) {
            navigate('/DashboardRevision');
        } else {
            navigate('/uploadResume');
        }
        
    };
    // Function to handle subscription cancellation
    const handleCancelSubscription = async () => {
        console.log('Cancel subscription');
        closeModal1();

        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/cancel-subscription', { //api endpoint
        method: 'POST',
        //---------------------------------auhentication code----------------------------------------
        headers: {
            Authorization: `Bearer ${token}`
        },
        //---------------------------------auhentication code----------------------------------------
        }).then(response => {
        // Check for the 400 status code 
        // console.log('Server Response:', response);
        if (response.status === 400) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
        }
        if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
        }
        //---------------------------------auhentication code----------------------------------------
        if (response.status == 401) {
            throw new Error('Sorry, you must login first')
        }
        //---------------------------------auhentication code----------------------------------------
        return response.json();
        }).then(data => {
        if(data.status == "success"){
            setSubscriptionCancelled(true);
            setIsSubscribed(false);
        }
        }).catch(error => {
        // handle the error
        console.error("handle subscription cancel failed:", error);
        if (error.message.includes('Unexpected token')) {
            console.error('Received non-JSON response:', error);
            // You might want to set a different state here to handle this specific error
        }
        //setLoading(false);
        alert(error.message);  // Displaying the error message to the user
        });
    };

    // cancel modal
    const openModal1 = () => {
        setIsModal1Open(true);
        document.body.style.overflow = "hidden";
      };
  
    const closeModal1 = () => {
          setIsModal1Open(false);
          document.body.style.overflow = "";
      };

    useEffect(() => {
        return () => {
            document.body.style.overflow = ""; // Restore scrolling
          };
    }, [isModal1Open]);


    // delete modal
    const openModal2 = () => {
        setIsModal2Open(true);
        document.body.style.overflow = "hidden";
      };
  
    const closeModal2 = () => {
          setIsModal2Open(false);
          document.body.style.overflow = "";
      };

    useEffect(() => {
        return () => {
            document.body.style.overflow = ""; // Restore scrolling
          };
    }, [isModal2Open]);

    if (loading) {    
        return <Loading_mainpage />;
    }

    return (
        isAuthenticated ? (
            <div className="dashboard-results">
                <Helmet>
                    {/* <!-- Google tag (gtag.js) --> */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
                    <script>
                    {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'G-F812QPTDD9');
                    `}
                    </script>
                    <title>Dashboard - ResuMaster</title>
                    <meta property="og:title" content="Dashboard - ResuMaster" />
                </Helmet>
                {
                    isAuthenticated ? (
                        <Navigation_loggedin className="navigation-2202" />
                    ) : (
                        <Navigation className="navigation-2202" />
                    )
                }
                <div className="announcement-banner">
                    <div className="banner-text">
                    Black Friday is Here! Enjoy 40% Off - Explore Our Exclusive Deals on the Pricing Page!
                    </div>
                </div>

                <div className="db_contents">

                <div className="db_sidebar">
                    <div className="dashboard-upper-buttons">
                        <span className='username'>Hi,{user.name}</span>
                        <div className="upload_resume">
                            <button className='upload_btn'
                                onClick={handleNewRevision}
                            >{currentResume && currentResume.Record_exist ? 'Start New Revision' : 'Upload New Resume'}</button>
                        </div >
                        <div className="db_side-list">
                            <button
                                    onClick={() => handleOptionChange('My Profile')}
                                    className={selectedOption === 'My Profile' ? 'selected-button' : ''}
                            >
                            <img className='my-profile'
                                        alt="My Resume Icon"
                                        src={selectedOption === 'My Profile' ? "/img/my_resume_2.png" : "/img/my_resume_1.png"} />
                                    My Resume
                            </button>

                            <button
                                    onClick={() => handleOptionChange('Revision History')} 
                                    className={selectedOption === 'Revision History' ? 'selected-button' : ''}
                            >
                                <img 
                                    className='revision-history'
                                    alt="Revision History Icon" 
                                    src={selectedOption === 'Revision History' ? "/img/revision_history_2.png" : "/img/revision_history_1.png"} />
                                    My Applications
                            </button>
                             <button
                                    onClick={() => handleOptionChange('Billing History')}
                                    className={selectedOption === 'Billing History' ? 'selected-button' : ''}
                            >
                                    <img 
                                    className='billing-history'
                                    alt="Billing History Icon" src={selectedOption === 'Billing History' ? "/img/billing_icon_2.png" : "/img/billing_icon_1.png"} />
                                    Billing
                            </button>
                        </div>
                    </div>
                        <div className="dashboard-bottom-buttons">
                            <p className='useremail'>{user.email}</p>

                            <button
                                 className='logout'
                                onClick={logout}
                            >
                                    
                            <img alt="Logout Icon" src="/img/dashboard_logout.png"  />
                                    Logout
                            </button>
                            
                        </div>
                </div>

                < div className="db_main-box">
                        {selectedOption === 'My Profile' && (
                            <div className="m_resume">
                                <h4 >My Resume</h4>
                                {currentResume && currentResume.Record_exist ? (
                                    <div className='currentData'>
                                        <CurrentResume data={currentResume.Record_content} removeResumeHandler={openModal2}/>
                                    </div>
                                
                                ):(
                                    <div className='blank_box'>
                                        <img src="/img/resume_empty.png" alt="resume empty icon" />
                                        <p>It looks like you haven't uploaded any resumes yet. To get started,upload your resume and begin tailoring it to your job applications. </p>
                                        <button onClick={handleNewRevision}>Upload Resume</button>
                                    </div>

                                )}
                                <div className={`dimmer-delete ${isModal2Open ? 'active' : ''}`}></div>
                                {isModal2Open && (
                                <div className="delete-resume-modal-profile">
                                  {console.log("Modal2 is now open")} {/* Console log here */}
                                  <div className="delete-resume-modal-profile-content">
                                    <img className='confirmation-icon'
                                        alt="confirmation logo icon"
                                        src="/img/logo-purple.png" />
                                      <h2>Delete Profile?</h2>
                                      <p>Your resume and profile information will be deleted. 
                                        This is a permanent action and cannot be undone. </p>
                                      <button className="delete-resume-confirm-button-yes" onClick={handleRemoveCurrentResume}>Yes</button>
                                      <button className="delete-resume-confirm-button-no" onClick={closeModal2}>No</button>
                                  </div>
                                </div>
                                )}

                            </div>
                        )}
                        {selectedOption === 'Revision History' && (
                            <div className="re_history">
                                <div className='application-history-temp-header'>
                                <h4>My Application</h4>
                                </div>
                                <div className="myapplication-control">
                                    <div className="myapplication-left-group">
                                        <div className="myapplication-add-application-container" onClick={openAddApplicationModal}>
                                            <img src="/img/add.png" alt="filter icon" width="16" height="16" style={{ marginRight: '5px' }} />
                                            <span style={{color: "#AAAAAA", fontFamily: 'Open Sans', fontWeight: '600', fontSize: '14px', marginLeft: '4px'}}>
                                                Add Application
                                            </span>
                                            <AddApplication 
                                            isOpen={isAddApplicationModalOpen} 
                                            onClose={closeAddApplicationModal}
                                            onSubmit={handleAddApplicationSubmit}
                                            />
                                        </div>
                                    </div>
                                    <div className="myapplication-right-group">                                    
                                        <CustomSort sortInfo={sortInfo} handleSortInfo={handleSortInfo} searchInput={searchInput} setSubmittedSearch={setSubmittedSearch}/>
                                        <CustomFilter filterInfo={filterInfo} handleFilterInfo={handleFilterInfo} searchInput={searchInput} setSubmittedSearch={setSubmittedSearch}/>
                                        {/* <div className="myapplication-search-container">
                                            <input type="text" id="myInput" name="myInput" placeholder="Find applications..." className="myapplication-custom-input"
                                                style={{border: 'none', outline: 'none', width: '100%', background: 'transparent', padding: '0', fontSize: '14px', marginLeft: '5px'}} />
                                            <img src="/img/search.png" alt="search icon" width="16" height="16"/>
                                        </div> */}
                                        <form onSubmit={handleSearchSubmit} className="myapplication-search-container">
                                            <input type="text" id="myInput" name="myInput" value={searchInput} onChange={(e) => setSearchInput(e.target.value)}
                                                placeholder="Find applications..." className="myapplication-custom-input"
                                                style={{border: 'none', outline: 'none', width: '100%', background: 'transparent', padding: '0', fontSize: '14px', marginLeft: '5px'}}/>
                                            <img src="/img/search.png" alt="search icon" width="16" height="16" onClick={handleSearchIconClick} style={{ cursor: 'pointer' }}/>
                                        </form>
                                    </div>
                                </div>
                                <div className="myapplication-divider"/>
                                <div className="myapplication-applications">
                                    <ApplicationHistory userApplicationsInfos={userApplicationsInfos} onSubmit={handleApplicationUpdate} onDelete={handleApplicationDelete} currentResume={currentResume}/>
                                    <div className="dashboard-logo-api">
                                        <a href="https://logo.dev" alt="Logo API">Logos provided by Logo.dev</a>
                                    </div>
                                </div>
                                
                            </div>
                        )}
                        
                        {selectedOption === 'Billing History' && (
                            <div id="bill_history">
                                <h4>Billing</h4>
                                {paymentHistory && (
                                    <div id='resuHistory'>
                                        <div className="billing-credit-balance">
                                            <div className="credit-balance-header">
                                                <p>ResuCredits</p>
                                                <Link to="/plan"><button className="view-plans-button">View all plans</button></Link>
                                            </div>
                                            <p className="billing-credit-balance-number">{credits}</p>
                                            {/* Render subscription renew date */}
                                            {isSubscribed && (
                                                <div className="billing-subscription-edit">
                                                    <div className="billing-subscription-edit-leftside">

                                                        <p>Renews on {subscriptionEnd}</p >
                                                        <button className="billing-update-payment-btn" onClick={fetchCustomerPortalLink}>Update Payment Method</button>

                                                    </div>
                                                    <button className="billing-cancel-subscription-btn" onClick={openModal1}>Cancel Subscription</button>
                                                </div>
                                            )}
                                            {subscriptionCancelled && (
                                                <div>{/* Render cancel confirmation & end date */}
                                                    <div className="billing-subscription-cancelled">Your subscription has been cancelled, enjoy unlimited credits until {subscriptionEnd}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='payment_list'>
                                            {displayedItems.map((item, index) => (
                                                <div key={index} className="payment_item">
                                                    <div className="item_price_row">
                                                        <div>{item.Item}</div>
                                                        <div>$ {item.price}</div>
                                                    </div>
                                                    <div className="timestamp_row">{item.time_stamp}</div>
                                                </div>
                                            ))}
                                        </div>
                                        {paymentHistory && paymentHistory.length > 0 && (
                                            <button onClick={() => setShowAll(!showAll)} className="toggle_button">
                                                {showAll ? 'Show less' : 'View more'}
                                            </button>
                                        )}
                                    </div>
                                )}
                                
                                <div className={`dimmer-cancel ${isModal1Open ? 'active' : ''}`}></div>
                                {isModal1Open && (
                                <div className="billing-modal-profile">
                                  {console.log("Modal is now open")} {/* Console log here */}
                                  <div className="billing-modal-profile-content">
                                    <img className='confirmation-icon'
                                        alt="confirmation logo icon"
                                        src="/img/logo-purple.png" />
                                      <h2>Cancel Subscription?</h2>
                                      <p>Are you sure you want to cancel your subscription? </p>
                                      <button className="billing-confirm-button-yes" onClick={handleCancelSubscription}>Yes</button>
                                      <button className="billing-confirm-button-no" onClick={closeModal1}>No</button>
                                  </div>
                                </div>
                                )}

                            </div>
                            
                        )}
                        
                    </div>

                    
                </div>
            </div >
        ) : (
            <div>Please log in first</div>
        ))

}
    
export default Dashboard;