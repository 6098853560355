import React, { useState } from "react";
import { Navigation } from "../../components/Navigation";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button } from "../../components/Button";
import { useNavigate } from 'react-router-dom';
import "./style.css";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------

export const Term_of_service = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  //---------------------------------auhentication code----------------------------------------
  //auth0 component
  const { isAuthenticated } = useAuth0();
  //---------------------------------auhentication code----------------------------------------

  return (
    <div className="Term_of_service">
      <Helmet>
        <title>Term of service - ResuMaster</title>
        <meta property="og:title" content="GetStarted - ResuMaster" />
      </Helmet>
      {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <Link to="/" className="back-button">
        <img className="back-button-img" src='/img/chevron-left.png' />
        <div className="back-button-text">Home</div>
      </Link>
      <div className="policy-get-started-header">
        Terms of Service
      </div>
      <div className="policy-flex-container">
      <p className="document_title">Last Updated: [11/01/2024]</p>
        <p className="document_text">Welcome to ResuMaster, a service provided by Craftix AI, Inc. ("Company," "we," "us," or "our"). 
            These Terms of Service ("Terms") govern your use of our website, applications, and services (collectively, the "Services"). 
            By accessing or using our Services, you agree to these Terms. If you do not agree with these Terms, please do not use our Services.
        </p>

        <p className="document_title">1. Acceptance of Terms</p>

        <p className="document_text">By using our Services, you agree to comply with and be bound by these Terms and our Privacy Policy. 
            If you do not agree to these Terms, you must not use our Services.
        </p>

        <p className="document_title">2. Eligibility</p>

        <p className="document_text">You must be at least 13 years old to use our Services. By using our Services, you represent and 
            warrant that you meet this age requirement and have the legal capacity to enter into a binding contract.
        </p>

        <p className="document_title">3. Account Registration</p>

        <p className="document_text">To access certain features of our Services, you are required to register for an account. 
            When you register, you agree to provide accurate, current, and complete information about yourself. You are responsible 
            for maintaining the confidentiality of your account information and for all activities that occur under your account.
        </p>

        <p className="document_title">4. User Conduct</p>

        <p className="document_text">You agree not to use our Services for any unlawful or prohibited purpose. You shall not violate any 
            applicable laws or regulations, infringe upon or violate our intellectual property rights or the intellectual property rights 
            of others, engage in any conduct that could harm, disable, overburden, or impair the functioning of our Services, or upload, 
            post, or transmit any viruses or other malicious code.
        </p>

        <p className="document_title">5. Intellectual Property</p>

        <p className="document_text">All content, features, and functionality available through our Services, including but not limited to text, 
            graphics, logos, icons, images, and software, are the property of Craftix AI, Inc. or its licensors and are protected by intellectual 
            property laws. You may not copy, modify, distribute, or use any content from our Services without our prior written permission.
        </p>

        <p className="document_title">6. Privacy</p>

        <p className="document_text">
            Your use of our Services is also governed by our Privacy Policy, which can be found at <a href="https://resumaster.ai/privacy_policy" target="_blank"> Privacy Policy</a>. By using our Services, you consent to the 
            collection, use, and disclosure of your information as described in our Privacy Policy.
        </p>

        <p className="document_title">7. Use License</p>

        <p className="document_text">Permission is granted to temporarily use ResuMaster's website for personal, non-commercial transitory 
            viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not modify or copy the 
            source materials; attempt to decompile or reverse engineer any software contained on ResuMaster's website; remove any copyright 
            or other proprietary notations from the materials; or transfer the materials to another person or "mirror" the materials on any 
            other server. This license shall automatically terminate if you violate any of these restrictions and may be terminated by Craftix AI, Inc. 
            at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded 
            materials in your possession whether in electronic or printed format.
        </p>

        <p className="document_title">8. Refund policy</p>

        <p className="document_text">Users are eligible for a full refund if they cancel their subscription within 24 hours of purchase or renewal, 
            provided that the service has not been utilized during this period. Users may cancel their subscription at any time prior to the 
            commencement of the next billing cycle. Refunds are not available for one-time credit purchases. 
        </p>

        <p className="document_title">9. Disclaimers</p>

        <p className="document_text">The materials on ResuMaster's website are provided on an 'as is' basis. Craftix AI, Inc. 
            makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without 
            limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement 
            of intellectual property or other violation of rights. Further, Craftix AI, Inc. does not warrant or make any representations 
            concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to 
            such materials or on any sites linked to this site.
        </p>

        <p className="document_title">10. Limitations</p>

        <p className="document_text">In no event shall Craftix AI, Inc. or its suppliers be liable for any damages (including, without limitation, 
            damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on 
            ResuMaster's website, even if Craftix AI, Inc. or a Craftix AI, Inc. authorized representative has been notified orally or in writing 
            of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of 
            liability for consequential or incidental damages, these limitations may not apply to you.
        </p>

        <p className="document_title">11. Accuracy of Materials</p>

        <p className="document_text">The materials appearing on ResuMaster's website could include technical, typographical, or 
            photographic errors. Craftix AI, Inc. does not warrant that any of the materials on its website are accurate, complete, 
            or current. Craftix AI, Inc. may make changes to the materials contained on its website at any time without notice. 
            However, Craftix AI, Inc. does not make any commitment to update the materials.
        </p>

        <p className="document_title">12. Termination of Services</p>

        <p className="document_text">We reserve the right to cancel any associated Services on the ResuMaster at our sole discretion. 
            This includes, but is not limited to, circumstances where we determine, based on reasonable evidence, that a user has violated 
            these Terms, engaged in prohibited conduct, or acted in a manner detrimental to the integrity or functionality of our Services. 
            Users may also request cancellation of their subscription at any time. Refund eligibility for cancellations is subject to our 
            Refund Policy outlined in Section 8.
        </p>

        <p className="document_title">13. Modifications</p>

        <p className="document_text">Craftix AI, Inc. may revise these terms of service for its website at any time without notice. 
            By using this website, you are agreeing to be bound by the then-current version of these terms of service.
        </p>

        <p className="document_title">14. Contact Us</p>

        <p className="document_text">If you have any questions about these Terms, please contact us at admin@resumaster.ai.
        </p>

      </div>

    </div>
  );
};